// Initially, this was just exporting Bunyan, but bunyan does not work well in the browser.
// This browser is used in the entire project, including the client, so it needs to behave well in the browser as well.
// Currently, a makeshift implementation is used that does not require importing a ton of external libs.

/* eslint-disable no-console */

const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key: string, value: any) => {
        if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};

const printObj = (obj: object) => {
    if (obj instanceof Error) {
        return obj;
    } else {
        return JSON.stringify(obj, getCircularReplacer(), 4);
    }
};

export enum LogLevel {
    debug,
    info,
    log,
    warn,
    error,
    fatal,
}

let logLevel: LogLevel = LogLevel.info;

const consoleLogOverride = (level: LogLevel, fnToOverride?: string) => {
    if (!fnToOverride) fnToOverride = LogLevel[level];

    return (...args: any[]) => {
        if (level >= logLevel) {
            const origFn: (typeof console)['log'] = console[fnToOverride as 'log'] as any;

            origFn.apply(
                null,
                args.map((arg) => (typeof arg === 'object' ? printObj(arg) : arg)),
            );
        }
    };
};

const logger = {
    debug: consoleLogOverride(LogLevel.debug, 'log'),
    info: consoleLogOverride(LogLevel.info),
    log: consoleLogOverride(LogLevel.log),
    warn: consoleLogOverride(LogLevel.warn),
    error: consoleLogOverride(LogLevel.error),
    fatal: consoleLogOverride(LogLevel.fatal, 'error'),

    level: (level: LogLevel) => {
        if (LogLevel[level] === undefined) {
            throw new Error('Unknown log level' + level + '. Please use the LogLevel enum to set it');
        }
        logLevel = level;
    },
};

export default logger;
