import { getPurchaseHistory } from './api/impl/utils/PurchaseHistory';
import type { PurchaseInfo } from './api/ReplicantAPI';
import type { Entry } from './db/DB';
import { teaHash } from './math/prng/prng';
import { EmptyRuleset, Ruleset } from './ReplicantRuleset';
import type { SharedStates } from './ReplicantSharedStates';
import SB from './SchemaBuilder';
import type { WithMeta } from './systemStateFields';
import { addNonEnumerableSystemStateFields, getSystemStateFieldsFromEntry } from './systemStateFields';
import type { Immutable } from './utils/TypeUtils';
import { FilterKeys } from './utils/TypeUtils';

/**
 * Internal representation of a computed property.
 *
 * Note that computed properties that are neither searchable or payload are used to populate the `adminTool.profile` fields in Replicant config.
 */
export interface ComputedProperty<TState, TSchema extends ComputedPropertySchema> {
    type: TSchema;
    getter: (state: TState, api: ComputedPropertiesAPI) => SB.ExtractType<TSchema>;

    readonly _searchable?: boolean;
    readonly _payload?: boolean;
}

export interface ComputedPropertySchema extends SB.Schema {
    _type: 'string' | 'number' | 'boolean' | 'object' | 'integer' | 'tuple' | 'array';

    /** Extend basic schema types like `string` with additional indexing capabilities. */
    _extendedType?: 'matchableString';
}

export type MatchableStringSchema = SB.StringSchema & { _extendedType: 'matchableString' };

/**
 * Use together with `searchableComputedProperty` to create a string computed property queryable by substrings.
 *
 * @see https://docs.dev.gc-internal.net/replicant/Indexing/#matchable-string-properties
 *
 */
export function matchableString(): MatchableStringSchema {
    const schema = SB.string() as MatchableStringSchema;
    schema._extendedType = 'matchableString';
    return schema;
}

export function computedProperty<TState, TSchema extends ComputedPropertySchema>(
    schema: TSchema,
    getter: (state: TState, api: ComputedPropertiesAPI) => SB.ExtractType<TSchema>,
): ComputedProperty<TState, TSchema> & {
    /**
     * Make the computed property a payload-only property.
     *
     * Payload properties are included in search results but cannot be used to construct search queries.
     *
     * @deprecated Replace `computedProperty(...).payload()` with `payloadComputedProperty(...)`.
     */
    payload: () => ComputedProperty<TState, TSchema> & { _payload: true };

    /**
     * Make the computed property searchable.
     *
     * Searchable properties can be used to construct queries and are also included in the search results along with payload properties.
     *
     * @deprecated Replace `computedProperty(...).searchable()` with `searchableComputedProperty(...)`.
     */
    searchable: () => ComputedProperty<TState, TSchema> & { _searchable: true };
} {
    return {
        type: schema,
        getter,
        payload: () => payloadComputedProperty(schema, getter),
        searchable: () => searchableComputedProperty(schema, getter),
    };
}

/**
 * Create a payload-only computed property.
 *
 * Payload properties are included in search results but cannot be used to construct search queries.
 *
 * Use together with `createComputedProperties`.
 */
export function payloadComputedProperty<TState, TSchema extends ComputedPropertySchema>(
    schema: TSchema,
    getter: (state: TState, api: ComputedPropertiesAPI) => SB.ExtractType<TSchema>,
): ComputedProperty<TState, TSchema> & { _payload: true } {
    if (schema._extendedType === 'matchableString') {
        throw Error(
            'matchableString is an invalid type for a payload computed property: use searchableComputedProperty instead',
        );
    }

    return { type: schema, getter, _payload: true };
}

/**
 * Create a searchable computed property.
 *
 * Searchable properties can be used to construct queries and are also included in the search results along with payload properties.
 *
 * Use together with `createComputedProperties`.
 */
export function searchableComputedProperty<TState, TSchema extends ComputedPropertySchema>(
    schema: TSchema,
    getter: (state: TState, api: ComputedPropertiesAPI) => SB.ExtractType<TSchema>,
): ComputedProperty<TState, TSchema> & { _searchable: true } {
    return { type: schema, getter, _searchable: true };
}

export type ComputedProperties<TState = any> = { [name: string]: ComputedProperty<TState, ComputedPropertySchema> };

/**
 * Create a computed properties configuration object, to be passed into `createConfig({ computedProperties, ... })`.
 *
 * @see https://docs.dev.gc-internal.net/replicant/Indexing/
 */
export function createComputedProperties<
    TUserState,
    TRuleset extends Ruleset = EmptyRuleset,
    TSharedStates extends SharedStates = {},
>(stateSchema: SB.Schema<TUserState>, opts?: { ruleset?: TRuleset; sharedStates?: TSharedStates }) {
    return <TComputedProperties extends ComputedProperties<WithMeta<TUserState, TRuleset, TSharedStates>>>(
        computedProperties: TComputedProperties,
    ) => computedProperties;
}

export type ComputedPropertiesAPI = {
    chatbot: {
        /**
         * @returns The timestamp for the last update of the android device token. Returns 0 if a token has not been set.
         *
         * Only usable with user state computed properties: calling from a shared state computed property results in an error.
         */
        getAndroidDeviceTokenUpdatedAt: () => number;

        /**
         * @returns The timestamp for the last update of the apple device token. Returns 0 if a token has not been set.
         *
         * Only usable with user state computed properties: calling from a shared state computed property results in an error.
         */
        getAppleDeviceTokenUpdatedAt: () => number;
    };

    date: {
        /** @returns The current server time as a millisecond timestamp, rounded down to the latest minute. The timestamp is rounded to prevent updating the index too frequently. */
        now: () => number;
    };

    /** @returns The clock offset set with the development-only `setClockOffset` action API. */
    getClockOffset: () => number;

    purchases: {
        /**
         * @returns A list of user's in-app purchases.
         *
         * Only usable with user state computed properties: calling from a shared state computed property results in an error.
         */
        getPurchaseHistory(): Immutable<PurchaseInfo[]>;
    };
};

export function createComputedPropertiesAPI(opts: {
    entry: Entry<any>;
    now: () => number;
    userId: string;
}): ComputedPropertiesAPI {
    const { entry, now, userId } = opts;

    const getRoundedNow = () => roundAndOffsetTimestamp(userId, now());

    return {
        date: { now: getRoundedNow },
        chatbot: {
            getAppleDeviceTokenUpdatedAt: () => entry.chatbotMetainfo?.appleDeviceTokenUpdatedAt || 0,
            getAndroidDeviceTokenUpdatedAt: () => entry.chatbotMetainfo?.androidDeviceTokenUpdatedAt || 0,
        },
        getClockOffset: () => entry.metainfo?.clockOffset || 0,
        purchases: { getPurchaseHistory: () => getPurchaseHistory(entry.metainfo) },
    };
}

export function resolveComputedProperties<TComputedProperties extends ComputedProperties>(opts: {
    api: ComputedPropertiesAPI;
    computedProperties?: TComputedProperties;
    docId: string;
    state: unknown;
}): { [K in keyof TComputedProperties]: ReturnType<TComputedProperties[K]['getter']> } {
    const { api, computedProperties, docId, state } = opts;

    const indexDoc = {} as { [K in keyof TComputedProperties]: ReturnType<TComputedProperties[K]['getter']> };

    for (const [key, computedProperty] of Object.entries(computedProperties || {})) {
        try {
            indexDoc[key as keyof typeof indexDoc] = computedProperty.getter(state, api);
        } catch (error: any) {
            error.message = `Failed to generate computed property '${key}' in document ${docId}: ${error.message}`;

            throw error;
        }

        try {
            computedProperty.type.tryValidate(indexDoc[key as keyof typeof indexDoc]);
        } catch (error: any) {
            error.message = `Invalid computed property '${key}' value of ${JSON.stringify(
                indexDoc[key],
            )} in document ${docId}: ${error.message}`;

            throw error;
        }
    }

    return indexDoc;
}

type IndexDocument<TComputedProperties extends ComputedProperties> = {
    [K in FilterKeys<TComputedProperties, { _searchable: true } | { _payload: true }>]: ReturnType<
        TComputedProperties[K]['getter']
    >;
};

/** @throws If the generated document doesn't match the computed properties schema. */
export function generateIndexDoc<TComputedProperties extends ComputedProperties>(
    userId: string,
    entry: Entry<any>,
    config: {
        sharedStates?: SharedStates;
        computedProperties?: TComputedProperties;
    },
    now: () => number,
): IndexDocument<TComputedProperties> {
    const roundedLastUpdated = roundAndOffsetTimestamp(userId, entry.lastUpdated);
    const entryWithRoundedLastUpdated = { ...entry, lastUpdated: Math.max(entry.createdAt, roundedLastUpdated) };

    addNonEnumerableSystemStateFields(
        entry.state,
        getSystemStateFieldsFromEntry(userId, entryWithRoundedLastUpdated),
        config,
    );

    const api = createComputedPropertiesAPI({ entry, now, userId });

    return resolveComputedProperties({
        api,
        computedProperties: getPayloadAndSearchableComputedProperties(config.computedProperties),
        docId: userId,
        state: entry.state,
    });
}

/** @throws If the generated document doesn't match the computed properties schema. */
export function generateSharedStateIndexDoc<TComputedProperties extends ComputedProperties>(
    sharedStateId: string,
    state: { global: unknown; users: { [userId: string]: unknown } },
    computedProperties: TComputedProperties,
    now: () => number,
): IndexDocument<TComputedProperties> {
    const throwNotSupportedError = () => {
        throw Error('This method is not supported by shared state computed properties');
    };
    const api: ComputedPropertiesAPI = {
        date: { now },
        chatbot: {
            getAppleDeviceTokenUpdatedAt: throwNotSupportedError,
            getAndroidDeviceTokenUpdatedAt: throwNotSupportedError,
        },
        getClockOffset: throwNotSupportedError,
        purchases: { getPurchaseHistory: throwNotSupportedError },
    };

    return resolveComputedProperties({
        api,
        computedProperties: getPayloadAndSearchableComputedProperties(computedProperties),
        docId: sharedStateId,
        state,
    });
}

function getPayloadAndSearchableComputedProperties<TComputedProperties extends ComputedProperties>(
    computedProperties?: TComputedProperties,
): TComputedProperties {
    const payloadComputedProperties = { ...computedProperties } as TComputedProperties;

    for (const [key, { _payload, _searchable }] of Object.entries(payloadComputedProperties)) {
        if (!_payload && !_searchable) {
            delete payloadComputedProperties[key];
        }
    }

    return payloadComputedProperties;
}

/**
 * Round `timestamp` down to the latest passed minute to prevent updating index on each replication,
 * and offset with hashed `userId` to avoid once-per-minute indexing spikes where all users get indexed at the same time.
 */
function roundAndOffsetTimestamp(userId: string, timestamp: number): number {
    const offsetMs = Math.floor(teaHash(userId) * 60 * 1000);

    return new Date(timestamp - offsetMs).setSeconds(0, 0);
}
