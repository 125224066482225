import SB from '../SchemaBuilder';
import { generateRandomId } from '../utils/Utils';

export const CHATBOT_MESSAGE_SUCCESS = 'ChatbotMessageSuccess';
export const CHATBOT_MESSAGE_FAILURE = 'ChatbotMessageFailure';

export const PUSH_NOTIFICATION_SUCCESS = 'PushNotificationSuccess';
export const PUSH_NOTIFICATION_FAILURE = 'PushNotificationFailure';

export const WEBPUSH_NOTIFICATION_SUCCESS = 'WebPushNotificationSuccess';
export const WEBPUSH_NOTIFICATION_FAILURE = 'WebPushNotificationFailure';

export const CHATBOT_SUBSCRIBE = 'ChatbotSubscribe';
export const CHATBOT_UNSUBSCRIBE = 'ChatbotUnsubscribe';
export const CHATBOT_WEBHOOK = 'ChatbotWebhook';

export const OTP_REQUEST_SUCCESS = 'OTPRequestSuccess';
export const OTP_REQUEST_FAILURE = 'OTPRequestFailure';
export const OTP_VERIFICATION_SUCCESS = 'OTPVerificationSuccess';
export const OTP_VERIFICATION_FAILURE = 'OTPVerificationFailure';

// STRIPE
export const PAYMENT_SUBSCRIPTION_CHARGE_FAILURE = 'PaymentSubscriptionChargeFailure';
export const PAYMENT_SUBSCRIPTION_CHARGE_SUCCESS = 'PaymentSubscriptionChargeSuccess';
export const PAYMENT_SUBSCRIPTION_CHARGE_REFUND = 'PaymentSubscriptionChargeRefund';
export const PAYMENT_SUBSCRIPTION_ABORT = 'PaymentSubscriptionAbort';
export const PAYMENT_SUBSCRIPTION_UPDATE = 'PaymentSubscriptionUpdate';
export const PAYMENT_SUBSCRIPTION_DELETE = 'PaymentSubscriptionDelete';

// Stripe Checkout:
export const CREATE_CHECKOUT_SESSION_SUCCESS = 'CreateCheckoutSessionSuccess';
export const CREATE_CHECKOUT_SESSION_FAILURE = 'CreateCheckoutSessionFailure';
export const CHECKOUT_SESSION_COMPLETE = 'CheckoutSessionComplete';

// Stripe Payment Intent:
export const CREATE_PAYMENT_INTENT_SUCCESS = 'CreatePaymentIntentSuccess';
export const CREATE_PAYMENT_INTENT_FAILURE = 'CreatePaymentIntentFailure';
export const PAYMENT_INTENT_SUCCESS = 'PaymentIntentSuccess';
export const PAYMENT_INTENT_FAILURE = 'PaymentIntentFailure';

// Komoju Payment:
export const CREATE_KOMOJU_PAYMENT_SUCCESS = 'CreateKomojuPaymentSuccess';
export const CREATE_KOMOJU_PAYMENT_FAILURE = 'CreateKomojuPaymentFailure';
export const KOMOJU_PAYMENT_SUCCESS = 'KomojuPaymentSuccess';
export const KOMOJU_PAYMENT_FAILURE = 'KomojuPaymentFailure';

export type AnalyticsEvent = {
    event_type: string;
    event_properties: { [key: string]: unknown };
    user_properties?: { [key: string]: unknown };
    insert_id?: string;
} & ({ user_id: string } | { device_id: string }); // Amplitude events must have either user or device ID

export type PostAnalyticsFN = (events: AnalyticsEvent[]) => Promise<void>;

let analyticsCounter = 0;
export function generateInsertId(userId: string, sessionId: string): string {
    return userId + '-' + sessionId + '-' + Date.now() + '-' + analyticsCounter++ + '-' + generateRandomId();
}

export class AnalyticsError extends Error {
    readonly eventNames: string[];

    constructor(message: string, events: AnalyticsEvent[]) {
        super('Analytics Error: ' + message);
        this.name = 'Analytics';
        this.eventNames = events.map((e) => e.event_type);
    }
}

const nullableString = SB.union([SB.string(), SB.unknown()]).customValidator((value) => {
    if (value !== null && typeof value !== 'string') {
        return 'Not a string or null: ' + value;
    }

    return null;
}) as SB.Schema<string | null>;

export const payloadSchema = SB.object({
    $channel: SB.string(),
    feature: SB.string(),
    $subFeature: nullableString,
}).additionalProperties();

export type AnalyticsPayload = SB.ExtractType<typeof payloadSchema>;
