import type { ReplicantEventHandlerAPI } from './api/ReplicantAPI';
import type { AsyncGetters } from './ReplicantAsyncGetters';
import type { ComputedProperties } from './ReplicantComputedProperties';
import { PartialReplicant } from './ReplicantConfig';
import { Messages } from './ReplicantMessages';
import { EmptyRuleset, Ruleset } from './ReplicantRuleset';
import type { SharedStates } from './ReplicantSharedStates';
import SB from './SchemaBuilder';
import { WithMeta } from './systemStateFields';
import { mapObject } from './utils/ObjUtils';

export type ScheduledActionsSchema<TSchema extends { [key: string]: SB.Schema }> = TSchema;

export type ScheduledActions<
    TState = any,
    TRuleset extends Ruleset = any,
    TMessages extends Messages<TState, TRuleset, TSharedStates> = any,
    TComputedProperties extends ComputedProperties = any,
    TSharedStates extends SharedStates = any,
    TAsyncGetters extends AsyncGetters<TState, TRuleset, TComputedProperties, TSharedStates> = any,
    TScheduledActionsSchema extends ScheduledActionsSchema<any> = any,
> = {
    [K in keyof TScheduledActionsSchema]: {
        schema: TScheduledActionsSchema[K];
        action: (
            state: WithMeta<TState, TRuleset, TSharedStates>,
            args: SB.ExtractType<TScheduledActionsSchema[K]>,
            api: ReplicantEventHandlerAPI<
                PartialReplicant<{
                    state: TState;
                    messages: TMessages;
                    ruleset: TRuleset;
                    computedProperties: TComputedProperties;
                    sharedStates: TSharedStates;
                    asyncGetters: TAsyncGetters;
                    scheduledActions: {
                        [D in keyof TScheduledActionsSchema]: {
                            schema: TScheduledActionsSchema[D];
                            // eslint-disable-next-line no-shadow
                            action: (state: any, args: any, api: any) => Promise<void> | void;
                        };
                    };
                }>
            >,
        ) => Promise<void> | void;
    };
};

export function createScheduledActions<
    TScheduledActionsSchema extends { [key: string]: SB.Schema },
    TStateSchema extends SB.Schema,
    TRuleset extends Ruleset = EmptyRuleset,
    TMessages extends Messages<SB.ExtractType<TStateSchema>, TRuleset, TSharedStates> = {},
    TComputedProperties extends ComputedProperties = {},
    TSharedStates extends SharedStates = {},
    TAsyncGetters extends AsyncGetters<SB.ExtractType<TStateSchema>, TRuleset, TComputedProperties, TSharedStates> = {},
>(
    stateSchema: TStateSchema,
    schema: TScheduledActionsSchema,
    _?: {
        ruleset?: TRuleset;
        messages?: TMessages;
        computedProperties?: TComputedProperties;
        sharedStates?: TSharedStates;
        asyncGetters?: TAsyncGetters;
    },
) {
    type TState = SB.ExtractType<TStateSchema>;
    type TScheduledActions = ScheduledActions<
        TState,
        TRuleset,
        TMessages,
        TComputedProperties,
        TSharedStates,
        TAsyncGetters,
        TScheduledActionsSchema
    >;

    return function createScheduledActionsFromSchema(actions: {
        [K in keyof TScheduledActions]: TScheduledActions[K]['action'];
    }): TScheduledActions {
        return mapObject(actions, (key, action) => ({ schema: schema[key] as any, action }));
    };
}
