import { APIMetainfo } from './api/ReplicantAPI';
import { Entry, UserSharedStateItems } from './db/DB';
import { Ruleset, EmptyRuleset, ABTestBucket } from './ReplicantRuleset';
import type { SharedStates, UserSharedStates } from './ReplicantSharedStates';
import { mapObject } from './utils/ObjUtils';
import { Immutable } from './utils/TypeUtils';

type SystemStateFieldsCommon = {
    /** Player ID. */
    id: string;

    /** Timestamp of player's creation. */
    createdAt: number;

    /**
     * Timestamp of player's last backend update.
     *
     * Only updated when player themselves performs an action - not when receiving messages.
     *
     * The value is rounded down to the latest minute when accessed inside a computed property getter. This is to prevent updating the index too frequently.
     */
    updatedAt: number;
};

type SystemStateFieldsInput<TSharedStates extends SharedStates> = Immutable<
    SystemStateFieldsCommon & {
        metainfo: Pick<APIMetainfo, 'abTestAssignments'>;
    }
> & {
    userSharedStates: UserSharedStateItems<TSharedStates>;
};

type SystemStateFieldsOutput<TRuleset extends Ruleset, TSharedStates extends SharedStates> = Immutable<
    SystemStateFieldsCommon & {
        ruleset: {
            abTests: {
                [K in keyof NonNullable<TRuleset['abTests']>]?: {
                    bucketId: ABTestBucket<NonNullable<TRuleset['abTests']>, K>;
                };
            };
        };

        /**
         * A map of the user shared states for this user. Not available in friends' states.
         *
         * @see https://docs.dev.gc-internal.net/replicant/shared-states/
         */
        userSharedStates: UserSharedStates<TSharedStates>;
    }
>;

export type WithMeta<TState, TRuleset extends Ruleset, TSharedStates extends SharedStates> = Omit<
    TState,
    keyof SystemStateFieldsOutput<TRuleset, TSharedStates>
> &
    SystemStateFieldsOutput<TRuleset, TSharedStates>;

export const systemStateFieldNames: (keyof SystemStateFieldsOutput<EmptyRuleset, SharedStates>)[] = [
    'id',
    'createdAt',
    'updatedAt',
    'ruleset',
    'userSharedStates',
];

export function addSystemStateFields<
    T extends Record<string, any>,
    TRuleset extends Ruleset,
    TSharedStates extends SharedStates,
>(
    state: T,
    systemStateFields: SystemStateFieldsInput<TSharedStates>,
    config: { sharedStates?: TSharedStates },
): WithMeta<T, TRuleset, TSharedStates> {
    Object.defineProperties(state, {
        id: { value: systemStateFields.id, configurable: true, enumerable: true, writable: true },
        createdAt: { value: systemStateFields.createdAt, configurable: true, enumerable: true, writable: true },
        updatedAt: { value: systemStateFields.updatedAt, configurable: true, enumerable: true, writable: true },
        ruleset: { get: () => getRuleset(systemStateFields.metainfo), configurable: true, enumerable: true },
        userSharedStates: {
            get: () => sanitizeUserSharedStates(config.sharedStates, systemStateFields.userSharedStates),
            configurable: true,
            enumerable: true,
        },
    });
    // TS doesn't like the type cast here, but is used in a bunch of places and removing/fixing it would likely break game code
    return state as unknown as WithMeta<T, TRuleset, TSharedStates>;
}

/**
 * Add system state fields as non-enumerable properties which do not show up in
 * JSON.stringify or break SchemaBuilder validation.
 */
export function addNonEnumerableSystemStateFields<
    T extends Record<string, any>,
    TRuleset extends Ruleset,
    TSharedStates extends SharedStates,
>(
    state: T,
    systemStateFields: SystemStateFieldsInput<TSharedStates>,
    config: { sharedStates?: TSharedStates },
): WithMeta<T, TRuleset, TSharedStates> {
    Object.defineProperties(state, {
        id: { value: systemStateFields.id, configurable: true, enumerable: false, writable: true },
        createdAt: { value: systemStateFields.createdAt, configurable: true, enumerable: false, writable: true },
        updatedAt: { value: systemStateFields.updatedAt, configurable: true, enumerable: false, writable: true },
        ruleset: { get: () => getRuleset(systemStateFields.metainfo), configurable: true, enumerable: false },
        userSharedStates: {
            get: () => sanitizeUserSharedStates(config.sharedStates, systemStateFields.userSharedStates),
            configurable: true,
            enumerable: false,
        },
    });
    // TS doesn't like the type cast here, but is used in a bunch of places and removing/fixing it would likely break game code
    return state as unknown as WithMeta<T, TRuleset, TSharedStates>;
}

export function getSystemStateFieldsFromEntry<TSharedStates extends SharedStates>(
    id: string,

    // Minimal entry.
    entry: Pick<Entry<unknown, TSharedStates>, 'createdAt' | 'lastUpdated' | 'userSharedStates'> & {
        metainfo: Pick<APIMetainfo, 'abTestAssignments'>;
    },
): SystemStateFieldsInput<TSharedStates> {
    return {
        id,

        createdAt: entry.createdAt,
        updatedAt: entry.lastUpdated,
        userSharedStates: entry.userSharedStates,

        metainfo: entry.metainfo,
    };
}

export function sanitizeUserSharedStates<TSharedStates extends SharedStates>(
    sharedStatesConfig: TSharedStates | undefined,
    userSharedStatesInput: UserSharedStateItems<TSharedStates>,
) {
    const result = {} as UserSharedStates<TSharedStates>;

    for (const stateName in sharedStatesConfig) {
        if (!sharedStatesConfig[stateName]?.schema.user) {
            continue;
        }

        result[stateName] = {};

        const statesByName = userSharedStatesInput?.[stateName];
        for (const stateId in statesByName) {
            const stateById = statesByName[stateId];
            if (!stateById) {
                continue;
            }

            result[stateName][stateId] = stateById.state;
        }
    }

    return result;
}

function getRuleset(metainfo: Pick<APIMetainfo, 'abTestAssignments'>) {
    const abTests = mapObject(metainfo.abTestAssignments || {}, (key, value) => {
        return value ? { bucketId: value.bucketId } : undefined;
    });

    return { abTests };
}
