import { unreachable } from './utils/Utils';

export const supportedPlatforms = [
    'discord',
    'fb',
    'line',
    'link',
    'snapchat',
    'viber',
    'tiktok',
    'zoom',
    'web',
] as const;
export type OnlinePlatform = (typeof supportedPlatforms)[number];
export type ReplicantPlatform = OnlinePlatform | 'mock';

export function toReplicantPlatform(platform: string, fallback?: ReplicantPlatform): ReplicantPlatform {
    switch (platform) {
        case 'discord':
        case 'fb':
        case 'line':
        case 'link':
        case 'snapchat':
        case 'viber':
        case 'zoom':
        case 'tiktok':
        case 'web':
        case 'mock':
            return platform;
        default:
            if (fallback) {
                return fallback;
            }

            throw new Error('Unsupported platform: ' + platform);
    }
}

export function parseReplicantPlatform(platform: string): ReplicantPlatform | null {
    let result: ReplicantPlatform | null = null;

    try {
        result = toReplicantPlatform(platform);
    } catch (e) {
        result = null;
    }

    return result;
}

export function platformRequiresAppSecret(platform: ReplicantPlatform): boolean {
    // App secret is required in web playable to generate and verify authentication tokens:
    if (process.env.WEB_PLAYABLE === 'true') {
        return true;
    }

    switch (platform) {
        case 'discord':
        case 'fb':
        case 'link':
        case 'tiktok':
        case 'viber':
        case 'web':
        case 'zoom':
            return true;
        case 'line':
        case 'mock':
        case 'snapchat':
            return false;
        default:
            unreachable(platform);
    }
}
