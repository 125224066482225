import type { ErrorReporter } from '../common/ErrorReporter';
import { ARGS_TOO_LARGE_WARNING_LIMIT_BYTES } from '../common/ReplicantConstants';
import type { UserMessageItem } from '../common/Types';
import type { SharedStateMessageItem } from '../db/DB';
import { ReplicantError } from '../Errors';
import type { EventHandlerMessage } from '../ReplicantEventHandlerMessages';
import SB from '../SchemaBuilder';
import { findLastIndex } from './ArrayUtils';
import { duration } from './Utils';

const MAX_FAILING_MESSAGE_QUEUE_SIZE = 25;
const MESSAGE_DELETE_AGE_THRESHOLD = duration({ days: 7 });

export function generateMessageId(timestamp: number, counter: number) {
    // 13 digits timestamp + 2 digits counter + 7 digits random
    const random = Math.random().toString().substr(2, 7);
    return timestamp + counter.toString().padStart(2, '0') + random.toString().padStart(7, '0');
}

export async function warnOnOversizeMessages(
    messages: { args: unknown; name: string; sender: string }[],
    errorReporter: ErrorReporter,
    extraProperties?: { [key: string]: unknown },
): Promise<void> {
    for (const message of messages) {
        if (!message.args) {
            continue;
        }

        const argsSize = JSON.stringify(message.args).length;

        if (argsSize > ARGS_TOO_LARGE_WARNING_LIMIT_BYTES) {
            const warning = new ReplicantError(
                `Message arguments size exceeds ${Math.round(
                    ARGS_TOO_LARGE_WARNING_LIMIT_BYTES / 1000,
                )} KB. Reduce arguments size to improve performance and to avoid hitting the database item limit.`,
                'server_error',
                'message_args_too_large',
                'warning',
                {
                    argsSize,
                    messageName: message.name,
                    ...extraProperties,
                },
            );

            await errorReporter.captureException(warning, { user: { id: message.sender } });
        }
    }
}

export function getUnreducedMessagesForDeletion(
    timestamp: number,
    unreducedMessages: UserMessageItem[],
): UserMessageItem[] {
    const toDelete: UserMessageItem[] = unreducedMessages.filter((f) => shouldDeleteMessage(f));

    function shouldDeleteMessage(message: { timestamp: number }): boolean {
        return message.timestamp + MESSAGE_DELETE_AGE_THRESHOLD < timestamp;
    }

    const nonExpiredMessages = unreducedMessages.filter((f) => !shouldDeleteMessage(f));
    if (nonExpiredMessages.length > MAX_FAILING_MESSAGE_QUEUE_SIZE) {
        // delete oldest messages except for the newest 25
        while (nonExpiredMessages.length > MAX_FAILING_MESSAGE_QUEUE_SIZE) {
            const message = nonExpiredMessages.shift();
            toDelete.push(message!);
        }
    }
    return toDelete;
}

type RecentlyReducedMessagesContainer = { recentMessageReductionLog?: { [key: string]: number } };

export function isMessageRecentlyReduced(
    item: RecentlyReducedMessagesContainer | undefined,
    message: UserMessageItem,
): boolean {
    return !!item?.recentMessageReductionLog?.['' + message.id];
}

export function updateRecentlyReducedMessages(
    currentTime: number,
    metainfo: RecentlyReducedMessagesContainer,
    messages: UserMessageItem[],
): void {
    metainfo.recentMessageReductionLog = Object.entries(metainfo.recentMessageReductionLog || {}).reduce(
        (acc, [id, timestamp]) => {
            if (timestamp + duration({ minutes: 1 }) > currentTime) {
                acc[id] = timestamp;
            }
            return acc;
        },
        {} as { [key: string]: number },
    );

    messages.forEach((m) => {
        metainfo.recentMessageReductionLog![m.id] = currentTime;
    });
}

export function getApplicableMessages<T extends UserMessageItem | SharedStateMessageItem<any, any>>(
    config: { [key: string]: { schema: SB.Schema } },
    messages: T[],
    opts?: {
        onUnknownMessage: (message: T) => void;
        onInvalidArgs: (message: T, validationError: string) => void;
    },
): T[] {
    const applicableMessages = messages.filter((message) => {
        if (isEventHandlerMessage(message)) {
            return true;
        }

        const messageConfig = config[message.name];
        if (!messageConfig) {
            // Ignore messages that we do not recognize. They may be sent to us from a player
            // playing a newer version of the game, or we may be requesting user profiles that
            opts?.onUnknownMessage(message);
            return false;
        }

        const validationError = messageConfig.schema.validate(message.args);
        if (validationError) {
            // Ignore messages with arguments that we do not recognize.
            // They may be sent to us from a player playing a different version of the game.
            opts?.onInvalidArgs(message, validationError);
            return false;
        }

        return true;
    });

    // Account for lastAppliedMessageId in sorting so that messages get applied in the same order they were applied on message write:
    const sortedMessages: T[] = [];
    const messagesWithLastAppliedMessageId: EventHandlerMessage[] = [];

    for (const message of applicableMessages) {
        const hasLastAppliedMessage =
            isEventHandlerMessage(message) &&
            !!message.lastAppliedMessageId &&
            applicableMessages.find(({ id }) => id === message.lastAppliedMessageId);

        if (hasLastAppliedMessage) {
            messagesWithLastAppliedMessageId.push(message);
        } else {
            sortedMessages.push(message);
        }
    }

    for (const messageToPush of messagesWithLastAppliedMessageId) {
        const pushAfterIndex = findLastIndex(
            sortedMessages,
            (msg) =>
                !!msg &&
                (msg.id === messageToPush.lastAppliedMessageId ||
                    (isEventHandlerMessage(msg) && msg.lastAppliedMessageId === messageToPush.lastAppliedMessageId)),
        );

        if (pushAfterIndex === -1) {
            throw Error('Cannot find last applied message index for messages ' + JSON.stringify(applicableMessages));
        }

        sortedMessages.splice(pushAfterIndex + 1, 0, messageToPush as T);
    }

    return sortedMessages;
}

export function isEventHandlerMessage(
    message: UserMessageItem | SharedStateMessageItem<any, any>,
): message is EventHandlerMessage {
    return 'event' in message;
}
