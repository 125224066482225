import { ReplicantError } from '../Errors';
import { parseAndValidateMediaType } from '../server/ServerAssetUploader';
import ReplicantHttpClient from './ReplicantHttpClient';

export default class ClientReplicantUserAssets {
    constructor(private httpClient: ReplicantHttpClient) {}

    async upload(dataUrl: string): Promise<string> {
        if (dataUrl.length > 940000) {
            throw new ReplicantError(
                'Maximum payload size exceeded for dataUrl: ' + dataUrl.length,
                'replication_error',
                'user_asset_upload_error',
            );
        }

        // Error out on unsupported media types.
        parseAndValidateMediaType(dataUrl);

        const json = await this.httpClient.doUploadUserAssetRequest(
            { dataUrl },
            {
                defaultErrorCodes: {
                    code: 'replication_error',
                    subCode: 'user_asset_upload_error',
                },
            },
        );

        if (!json.data || !json.data.assetUrl) {
            throw new ReplicantError(
                'Invalid user asset upload response.',
                'replication_error',
                'user_asset_upload_error',
            );
        }

        return json.data.assetUrl;
    }
}
