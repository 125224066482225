// Stubs for the client.

import { teaRandom } from '../../math/prng/prng';

import { ChatbotMetainfo, SharedStateUserItem, UserSharedStateItems } from '../../db/DB';
import { AsyncGetters } from '../../ReplicantAsyncGetters';
import { Replicant } from '../../ReplicantConfig';
import { Message, Messages } from '../../ReplicantMessages';
import { mapObject } from '../../utils/ObjUtils';
import { APIMetainfo, PostMessagesMap, ReplicantAsyncActionAPI, ReplicantSyncActionAPI } from '../ReplicantAPI';
import { getPurchaseHistory } from './utils/PurchaseHistory';
import { generateRandomId } from '../../utils/Utils';
import { isAndroidDeviceTokenExpired, isAppleDeviceTokenExpired } from '../../utils/DeviceTokenUtils';
import { ABTestBucket } from '../../ReplicantRuleset';
import { ReplicantABTests } from '../../common/ReplicantABTests';
import { SharedState, SharedStatesAsyncActionAPI } from '../../ReplicantSharedStates';
import type { WebPushSubscription } from '../../utils/WebPush';

type OnMessagePosted<TMessages extends Messages> = (recipientId: string, message: Message<TMessages>) => void;

function createStub<T extends Replicant>(opts: {
    isAsync: boolean;
    id: () => string;
    sessionId: () => string;
    apiMetainfo: () => APIMetainfo;
    userSharedStates: () => UserSharedStateItems<T['sharedStates']>;
    chatbotMetainfo: ChatbotMetainfo;
    invokeTime: () => number;
    messages?: T['messages'];
    scheduledActions?: T['scheduledActions'];
    computedProperties?: T['computedProperties'];
    sharedStates: T['sharedStates'] | undefined;
    onMessagePosted?: OnMessagePosted<T['messages']>;
    asyncGetters?: T['asyncGetters'];
    userAssetsBaseUrl: () => string;
    abTestsApiAccess: () => ReplicantABTests;
}) {
    const { isAsync, id, apiMetainfo, chatbotMetainfo, invokeTime, messages, onMessagePosted } = opts;

    function throwIfNotAsync(): any {
        if (!isAsync) {
            throw new Error('Do not use this function in a sync Replicant action.');
        }
    }

    function noop(): any {
        return null;
    }

    const getClockOffset = () => apiMetainfo().clockOffset || 0;

    // Stub for the messages.
    const messagesAPI: PostMessagesMap<T['messages']> = mapObject(messages, (opName, message) => {
        if (onMessagePosted) {
            return (userId, args) => {
                if (message.isAdmin) {
                    throw new Error('Cannot send admin messages from with an action.' + opName);
                }

                message.schema.tryValidate(args);

                onMessagePosted(userId, {
                    name: opName,
                    args,
                    sender: id(),
                    timestamp: invokeTime() + getClockOffset(),
                    id: '0', // not used by hander, just kept in storage.
                });
            };
        } else {
            return (_, args) => {
                if (message.isAdmin) {
                    throw new Error('Cannot send admin messages from with an action.' + opName);
                }

                message.schema.tryValidate(args);
            };
        }
    });

    const sharedStates: SharedStatesAsyncActionAPI<T['sharedStates']> = mapObject(
        opts.sharedStates,
        (stateName, sharedStateRaw) => {
            const sharedState: SharedState = sharedStateRaw as any;

            return {
                create: throwIfNotAsync,
                fetch: throwIfNotAsync,
                count: throwIfNotAsync,
                search: throwIfNotAsync,

                setUserState: (stateId, payload) => {
                    sharedState.schema.user?.schema.tryValidate(payload);

                    const userSharedStates = opts.userSharedStates();

                    const userItem: SharedStateUserItem<T['sharedStates'], typeof stateName> = {
                        rev: userSharedStates[stateName]?.[stateId]?.rev ?? 0,
                        state: payload,
                        stateId,
                        stateName,
                        userId: id(),
                        version: 0,
                    };

                    userSharedStates[stateName] = {
                        ...userSharedStates[stateName],
                        [stateId]: userItem,
                    };
                },

                deleteUserState: (stateId) => {
                    const userSharedStates = opts.userSharedStates();

                    delete userSharedStates[stateName]?.[stateId];
                },

                postMessage: sharedState.messages
                    ? mapObject(sharedState.messages, (messageName, message) => {
                          return (receiverId: unknown, args: unknown) => {
                              message.schema.tryValidate(args);
                          };
                      })
                    : ({} as any),
            };
        },
    );

    // Stub for scheduled actions
    const scheduledActionsMap = mapObject(
        opts.scheduledActions,
        (name, { schema }) =>
            ({ args }: { args: unknown }) => {
                schema.tryValidate(args);
            },
    );

    const random = () => (isAsync ? throwIfNotAsync() : teaRandom(id(), apiMetainfo().random));

    const api: ReplicantAsyncActionAPI<T> & ReplicantSyncActionAPI_Meta<T> = {
        asyncGetters: mapObject(opts.asyncGetters, () => throwIfNotAsync),

        fetch: throwIfNotAsync,

        math: {
            random,
        },

        getUserID: () => id(),
        getSessionID: () => opts.sessionId(),

        date: {
            now: () => {
                api.meta.hasUsedDateNow = true;
                return invokeTime() + api.getClockOffset();
            },
        },

        fetchStates: throwIfNotAsync,

        flushMessages: throwIfNotAsync,

        kvStore: {
            get: throwIfNotAsync,
            getBatch: throwIfNotAsync,
            send: throwIfNotAsync,
            sendBatch: throwIfNotAsync,
        },

        loginLinks: {
            createLoginToken: throwIfNotAsync,
            createWebPlayerToken: throwIfNotAsync,
        },

        getMentionCountForPagePost: throwIfNotAsync,

        abTests: {
            getBucketID: (testId) =>
                opts.abTestsApiAccess().getBucketID(apiMetainfo(), testId) as
                    | ABTestBucket<NonNullable<T['ruleset']['abTests']>, typeof testId>
                    | undefined,

            assign: (testId, bucketId) => {
                opts.abTestsApiAccess().assign(apiMetainfo(), id(), testId, bucketId);
            },
            unassign: (testId) => {
                opts.abTestsApiAccess().unassign(apiMetainfo(), id(), testId);
            },
        },

        otp: { verifyOtp: throwIfNotAsync },

        postMessage: messagesAPI,

        scheduledActions: {
            schedule: scheduledActionsMap,
            unschedule: noop,
            rescheduleAllBy: noop,
        },

        payments: {
            createCheckoutSession: throwIfNotAsync,
            createInvoice: throwIfNotAsync,
            createKomojuSession: throwIfNotAsync,
            createKomojuPayment: throwIfNotAsync,
            createPaymentIntent: throwIfNotAsync,
        },

        paymentSubscriptions: {
            initiate: throwIfNotAsync,
            initiateWithOTPVerification: throwIfNotAsync,
            verify: throwIfNotAsync,
            verifyOTP: throwIfNotAsync,
            getStatus: throwIfNotAsync,
            cancel: throwIfNotAsync,
        },

        searchPlayers: throwIfNotAsync,
        countPlayers: throwIfNotAsync,

        purchases: {
            getPurchaseHistory: () => getPurchaseHistory(apiMetainfo()),
            validatePurchase: throwIfNotAsync,
        },

        sendAnalyticsEvents: noop,

        reportError: noop,

        sharedStates,

        meta: { hasUsedDateNow: false, apiMetainfo, userSharedStates: opts.userSharedStates },

        chatbot: {
            sendMessage: noop,
            sendLineMessage: noop,
            sendInstagramMessage: noop,
            sendTelegramMessage: noop,
            sendMessengerMessage: noop,
            validateSubscription: noop,
            unsubscribe: noop,
            setAppleDeviceToken: (deviceToken) => {
                // Update the local copy of chatbotMetainfo, so querying it via client replicant
                // will receive the updated version.
                if (deviceToken) {
                    chatbotMetainfo.appleDeviceToken = deviceToken;
                    chatbotMetainfo.appleDeviceTokenUpdatedAt = api.date.now();
                } else {
                    delete chatbotMetainfo.appleDeviceToken;
                    delete chatbotMetainfo.appleDeviceTokenUpdatedAt;
                }
            },
            getAppleDeviceTokenUpdatedAt: () => chatbotMetainfo.appleDeviceTokenUpdatedAt || 0,
            appleDeviceTokenIsValid: () => {
                const tokenExpired = chatbotMetainfo.appleDeviceTokenUpdatedAt
                    ? isAppleDeviceTokenExpired(chatbotMetainfo.appleDeviceTokenUpdatedAt, api.date.now())
                    : false;

                return !tokenExpired && !!chatbotMetainfo.appleDeviceToken;
            },
            setAndroidDeviceToken: (deviceToken) => {
                if (deviceToken) {
                    chatbotMetainfo.androidDeviceToken = deviceToken;
                    chatbotMetainfo.androidDeviceTokenUpdatedAt = api.date.now();
                } else {
                    delete chatbotMetainfo.androidDeviceToken;
                    delete chatbotMetainfo.androidDeviceTokenUpdatedAt;
                }
            },
            getAndroidDeviceTokenUpdatedAt: () => chatbotMetainfo.androidDeviceTokenUpdatedAt || 0,
            androidDeviceTokenIsValid: () => {
                const tokenExpired = chatbotMetainfo.androidDeviceTokenUpdatedAt
                    ? isAndroidDeviceTokenExpired(chatbotMetainfo.androidDeviceTokenUpdatedAt, api.date.now())
                    : false;

                return !tokenExpired && !!chatbotMetainfo.androidDeviceToken;
            },
            linkRichMenuToUser: noop,
            unlinkRichMenuFromUser: noop,
            setEmail: (email) => {
                chatbotMetainfo.email = email;
            },

            setWebPushSubscription: (pushSubscription: WebPushSubscription | undefined) => {
                chatbotMetainfo.webPushSubscription = pushSubscription;
            },

            subscribeSms: (consentText: string) => {
                if (!consentText) throw new Error('consentText is required');
                chatbotMetainfo.smsSubscribed = true;
            },

            unsubscribeSms: () => {
                chatbotMetainfo.smsSubscribed = false;
            },
        },

        generateOrGetNativeBridgeSecret: () => {
            if (!chatbotMetainfo.nativeBridgeSecret) {
                // The server should generate the same secret.
                chatbotMetainfo.nativeBridgeSecret = generateRandomId(random);
            }

            return chatbotMetainfo.nativeBridgeSecret;
        },

        nukeUserMetainfo: () => {
            apiMetainfo().random = { n: 0 };
            apiMetainfo().clockOffset = 0;
            delete apiMetainfo().purchaseHistory;
            delete apiMetainfo().lastSessionId;
            delete apiMetainfo().appVersion;
        },

        setClockOffset: (millis: number) => {
            let stage: string | undefined;
            try {
                stage = process.env.STAGE;
            } catch {
                // Ignore errors on undefined `process.env` to handle builds without DefinePlugin
            }

            if (stage === 'prod') {
                throw new Error('setClockOffset() cannot be used in prod');
            }

            apiMetainfo().clockOffset = Math.floor(millis);
        },

        getClockOffset,

        getPushNotificationLastTargetedAt: throwIfNotAsync,

        getUserAssetUrl: (assetId: string) => opts.userAssetsBaseUrl() + assetId,
    };

    return api;
}

type ReplicantSyncActionAPI_Meta<T extends Replicant> = {
    meta: {
        hasUsedDateNow?: boolean;
        apiMetainfo: () => APIMetainfo;
        userSharedStates: () => UserSharedStateItems<T['sharedStates']>;
    };
};

export type CacheableReplicantSyncActionAPI<T extends Replicant> = ReplicantSyncActionAPI<T> &
    ReplicantSyncActionAPI_Meta<T>;

export const createReplicantAPIClientUtil = <T extends Replicant>(opts: {
    id: () => string;
    sessionId: () => string;
    apiMetainfo: () => APIMetainfo;
    userSharedStates: () => UserSharedStateItems<T['sharedStates']>;
    chatbotMetainfo: ChatbotMetainfo;
    invokeTime: () => number;
    messages?: T['messages'];
    scheduledActions?: T['scheduledActions'];
    sharedStates: T['sharedStates'] | undefined;
    onMessagePosted?: OnMessagePosted<T['messages']>;
    userAssetsBaseUrl: () => string;
    abTestsApiAccess: () => ReplicantABTests;
}): CacheableReplicantSyncActionAPI<T> => {
    return createStub({ ...opts, isAsync: false });
};

export const createReplicantAPIClient = <T extends Replicant>(opts: {
    id: () => string;
    sessionId: () => string;
    apiMetainfo: () => APIMetainfo;
    userSharedStates: () => UserSharedStateItems<T['sharedStates']>;
    chatbotMetainfo: ChatbotMetainfo;
    invokeTime: () => number;
    messages?: T['messages'];
    scheduledActions?: T['scheduledActions'];
    computedProperties?: T['computedProperties'];
    asyncGetters?: AsyncGetters<any>;
    sharedStates: T['sharedStates'] | undefined;
    onMessagePosted?: OnMessagePosted<T['messages']>;
    userAssetsBaseUrl: () => string;
    abTestsApiAccess: () => ReplicantABTests;
}): ReplicantAsyncActionAPI<T> => {
    const api = createStub({ ...opts, isAsync: true });

    // strip the meta object, as we don't need it for the async stub
    // (it's wrapped with the returned values from the server)
    delete (api as typeof api & { meta?: any }).meta;

    return api;
};
