import { ReplicantError } from '../Errors';
import { atob, setTimeout } from '../utils/EnvUtils';
import { duration } from '../utils/Utils';

export default class ClientSignatureManager {
    private signature?: string;
    private token?: string;

    private signatureExpiresAt?: number;
    private tokenExpiresAt?: number;

    /** How many milliseconds before expiration we start refreshing. */
    private signatureRefreshOffset = duration({ hours: 1 });

    private timeoutHandle?: number | NodeJS.Timeout;

    private refreshSignaturePromise?: Promise<void>;
    private refreshTokenPromise?: Promise<void>;

    constructor(
        private options: {
            initialSignature: string | undefined;

            obtainSignature: (() => Promise<string>) | undefined;
            obtainToken: () => Promise<string>;
            now: () => number;
        },
    ) {
        if (options.initialSignature) {
            this.setSignature(options.initialSignature);
        } else {
            this.refreshSignature();
        }
    }

    pause() {
        if (this.timeoutHandle) {
            clearTimeout(this.timeoutHandle as number);
            delete this.timeoutHandle;
        }
    }

    resume() {
        this.pause();

        if (!this.options.obtainSignature) {
            return;
        }

        const timeUntilSignatureUpdate = this.getTimeUntilSignatureUpdate();

        if (timeUntilSignatureUpdate > 0) {
            this.timeoutHandle = setTimeout(() => this.refreshSignature(), timeUntilSignatureUpdate);
        } else {
            this.refreshSignature();
        }
    }

    async getSignature() {
        if (this.getTimeUntilSignatureUpdate() <= 0) {
            // Update the signature if it expires soon, in case the timer didn't start it.
            this.refreshSignature();

            // Only block if the current signature is expired.
            if (this.isSignatureExpired()) {
                await this.refreshSignaturePromise;
            }
        }

        return this.signature;
    }

    setSignature(newSignature: string) {
        this.signature = newSignature;

        if (!this.options.obtainSignature) {
            return;
        }

        this.pause();

        try {
            const secondpart = newSignature.split('.')[1]!;
            const dataStr = atob(secondpart);
            const data = JSON.parse(dataStr);

            if (data.exp) {
                // Snapchat tokens include an `exp` field and are only valid for 5 minutes:
                this.signatureExpiresAt = duration({ seconds: data.exp });
                this.signatureRefreshOffset = duration({ minutes: 1 });
            } else {
                // Facebook and Viber tokens don't have an `exp` field so we use a long 24h expiration:
                const signatureIssuedAt = duration({ seconds: data.issued_at }); // Time in seconds -> time in ms.
                this.signatureExpiresAt = signatureIssuedAt + duration({ hours: 24 });
            }
        } catch (e) {
            // Assume it is a LINE token. TODO: This expiration information should be
            // based on thereplicant user token instead. And the expiration information of
            // the platform token should be computed on the replicant backend
            this.signatureExpiresAt = this.options.now() + duration({ hours: 24 });
        }

        this.resume();
    }

    async getToken() {
        if (this.isTokenExpired()) {
            void this.refreshToken();
        }

        await this.refreshTokenPromise;

        return this.token!;
    }

    setToken(newToken: string) {
        this.token = newToken;

        try {
            const secondpart = newToken.split('.')[1]!;
            const payloadStr = atob(secondpart);
            const payload = JSON.parse(payloadStr);

            this.tokenExpiresAt = duration({ seconds: payload.exp }); // Time in seconds -> time in ms.
        } catch (e: any) {
            throw new ReplicantError(e.message, 'authorization_error', 'invalid_signature');
        }
    }

    //

    private refreshSignature() {
        const obtainSignature = this.options.obtainSignature;
        if (!obtainSignature) {
            return;
        }

        if (!this.refreshSignaturePromise) {
            this.pause();

            this.refreshSignaturePromise = obtainSignature()
                .then((signature) => this.setSignature(signature))
                .finally(() => delete this.refreshSignaturePromise);
        }
    }

    private refreshToken() {
        if (!this.refreshTokenPromise) {
            this.refreshTokenPromise = this.options
                .obtainToken()
                .then((token) => this.setToken(token))
                .finally(() => delete this.refreshTokenPromise);
        }

        return this.refreshTokenPromise;
    }

    private getTimeUntilSignatureUpdate() {
        if (this.signatureExpiresAt === undefined) {
            return 0;
        }

        return this.signatureExpiresAt - this.options.now() - this.signatureRefreshOffset;
    }

    private isSignatureExpired() {
        // We start wanting to update it before it actually expires.
        return this.getTimeUntilSignatureUpdate() <= this.signatureRefreshOffset;
    }

    private isTokenExpired() {
        if (this.tokenExpiresAt === undefined) {
            return true;
        }

        // Mark the token as expired a bit earlier.
        // This way, the user will be forced to fetch a new token earlier,
        // and avoid potential errors due to network delays and clock sync.
        const earlyExpiration = this.signatureRefreshOffset / 2;

        return this.tokenExpiresAt - earlyExpiration < this.options.now();
    }
}
