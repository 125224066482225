import { FriendsStatesMap } from '../common/FriendsStatesMap';
import { MAX_REQUEST_DURATION } from '../common/ReplicantConstants';
import { ReplicantErrorCode } from '../Errors';
import logger from '../logger';
import { OnErrorHandler, Replicant, ReplicantConfig } from '../ReplicantConfig';
import { Message } from '../ReplicantMessages';
import { Ruleset } from '../ReplicantRuleset';
import { addNonEnumerableSystemStateFields, getSystemStateFieldsFromEntry } from '../systemStateFields';
import { copyModifications } from '../utils/ObjUtils';
import { Immutable } from '../utils/TypeUtils';
import { InternalReplicantOptions } from './ClientReplicant';
import { computeClockOffset } from './LoginOrCreateUser';
import ReplicantHttpClient from './ReplicantHttpClient';

type FriendsStatesChangedInfo = {
    isResponseToFirstSetFriendsIdsFetch: boolean;
};

type OnFriendsStatesChangedHandler<TState, TRuleset extends Ruleset> = (
    friendsState: FriendsStatesMap<TState, TRuleset>,
    info: FriendsStatesChangedInfo,
) => void;

// Public interface.
export interface ClientReplicantFriendsManager<TUserState, TRuleset extends Ruleset> {
    setFriendsIds(friendsIds: string[]): Promise<FriendsStatesMap<TUserState, TRuleset>>;

    getFriendsStates(): Immutable<FriendsStatesMap<TUserState, TRuleset>>;

    setOnFriendsStatesChangedHandler(handler: OnFriendsStatesChangedHandler<TUserState, TRuleset>): void;
    setOnErrorHandler(handler: OnErrorHandler): void;

    stopCheckingForUpdates(): void;
}

export class ClientReplicantFriends<T extends Replicant>
    implements ClientReplicantFriendsManager<T['state'], T['ruleset']>
{
    private friendsIds: string[] = [];
    private friendsStates: FriendsStatesMap<T['state'], T['ruleset']> = {};

    private fetchFriendsTimer: NodeJS.Timeout | undefined = undefined;
    private fetchInterval: number;

    private externalFriendsStates: FriendsStatesMap<T['state'], T['ruleset']> = {}; // the copy that is given to onChangedHandler

    private onFriendsChanged: OnFriendsStatesChangedHandler<T['state'], T['ruleset']> | undefined;
    private onErrorHandler: OnErrorHandler | undefined;

    private updateLocks: { [id: string]: { [batchId: string]: Message<T['messages']>[] } } = {};

    // Interval for testing purposes.
    private onFriendStatesReceivedHandlers: ((result: FriendsStatesMap<T['state'], T['ruleset']>) => void)[] = [];

    // Messages sent during an action.
    private messages: { id: string; message: Message<T['messages']> }[] = [];

    // Signaling that app is paused.
    private isPaused: boolean = false;

    private timestampLastFetched: number = 0;

    private didCallSetFriendsIds = false;
    private isOffline = false;

    /**
     * This flag is used to resolve race condition, rarely occurring because
     * this.updateFriendsStates() call in setFriendsIds method does not wait
     * for promise completion. For consistency of reading the code, and for
     * optimization of calls to copyModifications(),  it was also added to
     * other places.
     */
    private pendingOnFriendsChangedCall = false;

    constructor(
        private opts: {
            config: ReplicantConfig<T>;
            options: InternalReplicantOptions;
            httpClient: ReplicantHttpClient<T>;
            adjustClockOffset: (newOffset: number) => void;
            calcConsistentFetchIds: () => string[];
        },
    ) {
        this.fetchInterval = opts.options.refreshFriendsStatesInterval || 0;
    }

    setFriendsIds(friends: string[]) {
        const isResponseToFirstSetFriendsIdsFetch = !this.didCallSetFriendsIds;
        this.didCallSetFriendsIds = true;

        const sortedFriends = friends.sort();
        const uniqueFriends = sortedFriends.filter((id, ind) => id !== sortedFriends[ind + 1]);

        if (
            uniqueFriends.length === this.friendsIds.length &&
            this.friendsIds.every((id, ind) => id === uniqueFriends[ind])
        ) {
            // Same set of friends, nothing to do.
            return Promise.resolve(this.friendsStates);
        }

        this.friendsIds = uniqueFriends;

        if (this.fetchFriendsTimer) {
            clearTimeout(this.fetchFriendsTimer);
        }

        return this.updateFriendsStates({ isResponseToFirstSetFriendsIdsFetch });
    }

    getFriendsStates() {
        return this.externalFriendsStates as Immutable<FriendsStatesMap<T['state'], T['ruleset']>>;
    }

    setOnFriendsStatesChangedHandler(handler: OnFriendsStatesChangedHandler<T['state'], T['ruleset']>) {
        this.onFriendsChanged = handler;
    }

    setOnErrorHandler(handler: OnErrorHandler) {
        this.onErrorHandler = handler;
    }

    stopCheckingForUpdates() {
        this.isPaused = true;
        if (this.fetchFriendsTimer) {
            clearTimeout(this.fetchFriendsTimer);
        }
    }

    // Internal API.

    async fetchOtherPlayerStates(ids: string[]) {
        const friendRevs = ids
            .filter((id) => !!this.friendsStates[id])
            .map((friendId) => ({ [friendId]: this.friendsStates[friendId]!.friendRev }))
            .reduce((revs, rev) => Object.assign(revs, rev), {});

        const response = await this.fetchStates(ids, friendRevs);

        if (response) {
            this.handleFetchStatesResponse(response);
        }

        const results: FriendsStatesMap<T['state'], T['ruleset']> = {};
        for (const id of ids) {
            results[id] = this.friendsStates[id]!;
        }

        return results;
    }

    pause() {
        this.stopCheckingForUpdates();
    }

    resume() {
        this.isPaused = false;

        // Schedule next update, so it's at least one interval away.
        if (this.fetchInterval > 0) {
            const timeToNext =
                this.timestampLastFetched === 0
                    ? 0
                    : Math.max(this.timestampLastFetched + this.fetchInterval - Date.now(), 0);

            if (this.fetchFriendsTimer) {
                clearTimeout(this.fetchFriendsTimer);
            }

            this.fetchFriendsTimer = setTimeout(() => this.updateFriendsStates(), timeToNext);

            if (this.pendingOnFriendsChangedCall) {
                this.triggerOnChangedHandlers();
            }
        }
    }

    handleReplicantMessage(id: string, message: Message<T['messages']>) {
        this.messages.push({ id, message });
    }

    handleReplicantActionCompleted(batchId: string) {
        for (const { id, message } of this.messages) {
            if (this.friendsStates[id]) {
                // Apply locally changes to user based on the last state we had.
                this.applyMessageToState(id, message);

                // Set an update lock for this friend, since we know that we shouldn't override this profile.
                if (!this.updateLocks[id]) {
                    this.updateLocks[id] = {};
                }

                this.updateLocks[id]![batchId] = (this.updateLocks[id]![batchId] || []).concat([message]);
            }
        }

        const changes = this.messages.length;

        this.messages = [];

        if (changes) {
            this.pendingOnFriendsChangedCall = true;
            this.triggerOnChangedHandlers();
        }
    }

    handleReplicationResultStates(states: FriendsStatesMap<T['state'], T['ruleset']>, batchId: string) {
        // Apply and lift locks or apply extra messages where appropriately.
        for (const id in states) {
            const entry = states[id]!;
            addNonEnumerableSystemStateFields(entry.state, getSystemStateFieldsFromEntry(id, entry), this.opts.config);

            this.friendsStates[id] = entry;

            if (this.updateLocks[id]) {
                delete this.updateLocks[id]![batchId];
                if (Object.keys(this.updateLocks[id]!).length > 0) {
                    // Has unconfirmed messages from other batches.
                    for (const key in this.updateLocks[id]) {
                        for (const msg of this.updateLocks[id]![key]!) {
                            this.applyMessageToState(id, msg);
                        }
                    }
                } else {
                    // Lift lock competely.
                    delete this.updateLocks[id];
                }
            }
        }

        // If any states have been changed, trigger handlers.
        if (Object.keys(states).length > 0) {
            this.pendingOnFriendsChangedCall = true;
            this.triggerOnChangedHandlers();
        }
    }

    clearLocks() {
        this.updateLocks = {};
    }

    async fetchFriendsStatesNow() {
        return this.updateFriendsStates();
    }

    setOnFriendsStatesReceived(fn: (result: FriendsStatesMap<T['state'], T['ruleset']>) => void) {
        this.onFriendStatesReceivedHandlers.push(fn);
    }

    clearFriendsStatesReceivedHandler() {
        this.onFriendStatesReceivedHandlers = [];
    }

    goOnline() {
        if (this.isOffline) {
            this.isOffline = false;
            this.scheduleFetchStates();
        }
    }

    //

    private applyMessageToState(id: string, msg: Message<T['messages']>) {
        if (this.opts.config.messages) {
            const userData = this.friendsStates[id]!;

            this.opts.config.messages[msg.name].reducer(userData.state, msg.args, {
                senderId: msg.sender,
                timestamp: msg.timestamp,
            });
        }
    }

    private async updateFriendsStates(
        info?: FriendsStatesChangedInfo,
    ): Promise<FriendsStatesMap<T['state'], T['ruleset']>> {
        let results: FriendsStatesMap<T['state'], T['ruleset']> | undefined;

        const friendRevs = Object.keys(this.friendsStates)
            .map((friendId) => ({ [friendId]: this.friendsStates[friendId]!.friendRev }))
            .reduce((revs, rev) => Object.assign(revs, rev), {});

        try {
            results = await this.fetchStates(this.friendsIds, friendRevs);

            this.timestampLastFetched = Date.now();
        } catch (e: any) {
            logger.error('Error fetching states: ', e);

            this.isOffline = e.code === ReplicantErrorCode.network_error;

            if (this.onErrorHandler) {
                this.onErrorHandler(e);
            }
        }

        if (!this.isOffline) {
            this.scheduleFetchStates();
        }

        if (results) {
            this.handleFetchStatesResponse(results, info);
        }

        return this.friendsStates;
    }

    private triggerOnChangedHandlers(info?: FriendsStatesChangedInfo) {
        if (this.isPaused || !this.pendingOnFriendsChangedCall) return;

        this.pendingOnFriendsChangedCall = false;

        const oldStates = this.externalFriendsStates;

        this.externalFriendsStates = copyModifications(this.externalFriendsStates, this.friendsStates);

        const friendsStatesChanged = oldStates !== this.externalFriendsStates;

        // Restore non-enumerable properties wiped by `copyModifications`.
        if (friendsStatesChanged) {
            for (const id in this.externalFriendsStates) {
                if (this.externalFriendsStates[id]!.state !== oldStates[id]?.state) {
                    addNonEnumerableSystemStateFields(
                        this.externalFriendsStates[id]!.state,
                        getSystemStateFieldsFromEntry(id, this.friendsStates[id]!),
                        this.opts.config,
                    );
                }
            }
        }

        if (this.onFriendsChanged && friendsStatesChanged) {
            this.onFriendsChanged(
                this.externalFriendsStates,
                info || {
                    isResponseToFirstSetFriendsIdsFetch: false,
                },
            );
        }
    }

    // TODO: Use public states for the fetch state as a security measure / optimization
    private async fetchStates(
        ids: string[],
        friendRevs: { [id: string]: number },
    ): Promise<FriendsStatesMap<T['state'], T['ruleset']>> {
        const batches: { ids: string[] }[] = [];

        // Heuristics for splitting fetching states into batches.
        // If we have new states that we have not seen before more than `initialPullMaxBatchSize`, we split requests
        // into batches of this size.
        // If we have fewer new states, the updates may be still result in a download size > 1MB. Hence, we split them
        // into bigger batches - `updatesMaxBatchSize`.

        const initialPullMaxBatchSize = 100;
        const updatesMaxBatchSize = 3 * initialPullMaxBatchSize;

        const newStatesCount = ids.filter((friendId) => !(friendId in friendRevs)).length;
        const maxBatchSize = newStatesCount > initialPullMaxBatchSize ? initialPullMaxBatchSize : updatesMaxBatchSize;

        const currentIds = ids.slice(0);
        while (currentIds.length > 0) {
            const batchIds = currentIds.splice(0, maxBatchSize);
            batches.push({ ids: batchIds });
        }

        const dateNow = this.opts.options.devOpts?.dateNow || Date.now;

        const jobs = batches.map(async (payload) => {
            const batchResult: FriendsStatesMap<T['state'], T['ruleset']> = {};

            let idsToFetch = payload.ids;

            do {
                const tStart = dateNow();
                const fetchStateResult = await this.opts.httpClient.doFetchStatesRequest({
                    ids: idsToFetch,
                    friendRevs,
                    consistentFetchIds: this.opts.calcConsistentFetchIds(),
                });
                const tEnd = dateNow();

                // Adjust clock if needed, but not if the request took too long.
                if (tEnd - tStart < MAX_REQUEST_DURATION) {
                    const clockOffset = computeClockOffset(tStart, tEnd, fetchStateResult);
                    this.opts.adjustClockOffset(clockOffset);
                }
                Object.assign(batchResult, fetchStateResult.data.states);

                idsToFetch = fetchStateResult.data.unprocessedIds || [];
            } while (idsToFetch.length > 0);

            return batchResult;
        });

        const batchResults = await Promise.all(jobs);
        // Merge results.
        const result: FriendsStatesMap<T['state'], T['ruleset']> = Object.assign({}, ...batchResults);

        for (const id in result) {
            const entry = result[id]!;
            addNonEnumerableSystemStateFields(
                result[id]!.state,
                getSystemStateFieldsFromEntry(id, entry),
                this.opts.config,
            );
        }

        if (result && Object.keys(result).length > 0) {
            this.pendingOnFriendsChangedCall = true;
        }

        return result;
    }

    private handleFetchStatesResponse(
        response: FriendsStatesMap<T['state'], T['ruleset']>,
        info?: FriendsStatesChangedInfo,
    ) {
        this.onFriendStatesReceivedHandlers.forEach((fn) => fn(response!));

        // Do not update any locked ids.
        for (const key in this.updateLocks) {
            if (response[key]) {
                response[key] = this.friendsStates[key]!;
            }
        }

        this.friendsStates = {
            ...this.friendsStates,
            ...response,
        };

        this.triggerOnChangedHandlers(info);
    }

    private scheduleFetchStates() {
        if (!this.isPaused && this.fetchInterval > 0) {
            this.fetchFriendsTimer = setTimeout(() => this.updateFriendsStates(), this.fetchInterval);
        }
    }
}
