// window method implementations compatible both with NodeJS and browser environments.

export const atob = (base64str: string): string =>
    typeof window === 'undefined' ? Buffer.from(base64str, `base64`).toString(`binary`) : window.atob(base64str);

export const btoa = (utf8str: string): string =>
    typeof window === 'undefined' ? Buffer.from(utf8str, 'binary').toString(`base64`) : window.btoa(utf8str);

export const setTimeout = (handler: () => void, timeout: number): number | NodeJS.Timeout =>
    typeof window === 'undefined' ? global.setTimeout(handler, timeout).unref() : window.setTimeout(handler, timeout);
