/** Chunk `arr` into an array of sub-arrays of size `chunkSize`. */
export function chunk<T>(arr: T[], chunkSize: number): T[][] {
    const chunks = [];

    for (let i = 0; i < arr.length; i += chunkSize) {
        chunks.push(arr.slice(i, i + chunkSize));
    }

    return chunks;
}

export function deduplicate<T>(arr: T[], deduplicateBy?: (item: T) => unknown): T[] {
    if (!deduplicateBy) {
        return arr.filter((item, i) => arr.indexOf(item) === i);
    }

    return arr.filter((item1, i) => {
        const key = deduplicateBy(item1);

        return arr.findIndex((item2) => deduplicateBy(item2) === key) === i;
    });
}

export function findLastIndex<T>(arr: T[], predicate: (item: T | undefined) => boolean): number {
    for (let i = arr.length - 1; i >= 0; i--) {
        if (predicate(arr[i])) {
            return i;
        }
    }

    return -1;
}

export function groupBy<T>(arr: T[], getKey: (item: T) => string): { [key: string]: T[] } {
    const groups: { [key: string]: T[] } = {};

    for (const item of arr) {
        const key = getKey(item);

        if (!groups[key]) {
            groups[key] = [];
        }

        groups[key]!.push(item);
    }

    return groups;
}

/** @returns Array of two arrays where the first array contains items `predicate` returns truthy for and the second array contains items `predicate` returns falsey for. */
export function partition<T>(arr: T[], predicate: (item: T | undefined) => boolean): [T[], T[]] {
    const truthyItems: T[] = [];
    const falseyItems: T[] = [];

    for (const item of arr) {
        if (predicate(item)) {
            truthyItems.push(item);
        } else {
            falseyItems.push(item);
        }
    }

    return [truthyItems, falseyItems];
}

export function sortBy<T>(arr: T[], predicate: (item: T) => any): T[] {
    return [...arr].sort((itemA, itemB) => {
        const a = predicate(itemA);
        const b = predicate(itemB);

        return a < b ? -1 : a === b ? 0 : 1;
    });
}
