import { KeyValuePairs, ReadOnlyKeyValueStoreAPI } from '../api/KeyValueStoreAPI';
import { ReplicantError } from '../Errors';
import { KvPairOutput } from '../server/ServerReplicant';
import ReplicantHttpClient from './ReplicantHttpClient';

/**
 * Accessor object to the internal KV store.
 *
 * Internal KV store values read-only to clients. Values are written by game developers using the Replicant CLI.
 */
export default class ClientInternalKeyValueStore implements ReadOnlyKeyValueStoreAPI {
    constructor(private httpClient: ReplicantHttpClient, private cache: KeyValuePairs = {}) {}

    async get(key: string, opts?: { forceFetch?: boolean }) {
        const pairs = await this.getBatch([key], opts);

        return pairs[key] || null;
    }

    async getBatch(keys: string[], opts?: { forceFetch?: boolean }) {
        const keysToFetch = opts && opts.forceFetch ? keys : keys.filter((key) => !(key in this.cache));

        if (keysToFetch.length) {
            await this.fetchBatch(keysToFetch);
        }

        const result: KeyValuePairs = {};
        for (const key of keys) {
            if (key in this.cache) {
                result[key] = this.cache[key]!;
            }
        }

        return result;
    }

    private async fetchBatch(keys: string[]): Promise<void> {
        const json: KvPairOutput = await this.httpClient.doGetInternalKeyValuePairsRequest(
            { keys },
            {
                defaultErrorCodes: {
                    code: 'replication_error',
                    subCode: 'key_value_storage_error',
                },
                retryOptions: { retries: 8 },
            },
        );

        if (!json.data) {
            throw new ReplicantError(
                'Invalid internal key value store fetch response.',
                'replication_error',
                'key_value_storage_error',
            );
        }

        for (const key in json.data) {
            this.cache[key] = json.data[key]!;
        }
    }
}
